// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


$(document).ready(function(){
  $.each($(".question-row input[type=radio]:checked"), function(index, ele){
    console.log(ele)
    $(".question-row[data-show-if-option="+$(ele).val()+"]").show()
  })
  $.each($(".question-row:visible .lp"), function(index, ele) { $(ele).text(index + 1)})
  $(".question-row input[type=radio]").change(function() {
    $(".question-row[data-show-if-question="+$(this).parents(".question-row").data("id")+"]").slideUp().addClass("inactive")
    $(".question-row[data-show-if-option="+$(this).val()+"]").slideDown().removeClass("inactive")
    $.each($(".question-row:visible:not('.inactive') .lp"), function(index, ele) { $(ele).text(index + 1)})
  })
})
